<template>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    getParam(){
      var token = this.$route.params.token;
      var site_id = this.$route.params.site_id;
      axios({
          url: process.env.VUE_GD_API_HOST+`/club/user/email-verification?token=${token}&site_id=${site_id}`,
          method: "GET",
      }).then((response) => {
        if(response.data.success === false){
          window.location.href = "https://" + window.location.host + '/home'
        }else{
          window.location.href = response.data.site+'?rdr='+response.data.string
        }
      }).catch((err)=>{
          window.location.href = "https://" + window.location.host + '/home'
      });
    }
  },
  mounted(){
    this.getParam();
  }
}
</script>

<style lang="scss" scoped>

</style>